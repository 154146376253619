import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'audamatic-ui';
import Icon from '../../../base_svg-icon/component';
import HeaderContext from '../../HeaderContext';
import { numFormatToCurrency, miniMustache } from '@utils';
import Text from '@text';
import { t } from '@utils/i18n';
import { HEADER } from '@config';

import './styles.scss';

/**
 * This component is rendered in the header of the website.
 * It shows the number of items in your cart as a badge oveer the cart icon.
 *
 * If you add a book to the cart this component shows a notification.
 * If the book is already in the basket it shows a text for that.
 */
const MiniBasket = () => {
  const history = useHistory();
  const ctx = useContext(HeaderContext);
  const { store } = ctx;
  const { checkoutUrl = '/winkelmand' } = HEADER;
  const { totals = {}, valueRebates = {} } = store.getBasket() || {};
  const shippingRebate = totals.basketShippingRebatesTotal?.value || 0;
  const discount = valueRebates[0]?.amount.value || 0;
  const timeOutDuration = 4000;
  const animationDuration = 100;

  useEffect(() => {
    store.events.subscribe('addToBasket', showMiniBasket);
    store.events.subscribe('alreadyInBasket', alreadyInBasket);
    store.events.subscribe('mountedCheckoutPage', closeNotification);
  }, []);

  let timer;
  const clearTimeOut = () => {
    if (timer) {
      window.clearTimeout(timer);
    }
  };

  const [notificationOpen, setNotificationOpen] = useState(false);
  const closeNotification = (e) => {
    e?.preventDefault?.();
    clearTimeOut();

    setNotificationOpen(false);
    setTimeout(() => {
      setNotificationText('');
      setProductAdded(null);
    }, animationDuration);
  };

  // Close notification and naviagte to checkout
  const handleNavigation = () => {
    closeNotification();
    setTimeout(() => {
      history.push(checkoutUrl);
    }, animationDuration);
  };

  const [notificationText, setNotificationText] = useState('');
  const [productAdded, setProductAdded] = useState();

  const notificationTexts = {
    changedItem: miniMustache({
      string: t('minibasket.availability.message'),
      replacements: { productAvailability: productAdded?.productAvailability },
      html: false,
    }),
    alreadyInBasket: t('minibasket.alreadyinbasket'),
  };

  /*
   * Open notification with a delay, so the content is already rendered
   * before changing the opacity of the mini basket
   */
  const openNotification = () => {
    setTimeout(() => {
      setNotificationOpen(true);
      clearTimeOut();
      timer = window.setTimeout(closeNotification, timeOutDuration);
    }, animationDuration);
  };

  const showMiniBasket = ({ sku }) => {
    const productAdded = store.getBasketProduct(sku);
    const changedItem = productAdded.deliveryDateChanged;
    const inBasketComponent = document.body.id === 'BasketPage';

    let text = '';
    if (changedItem) {
      text = 'changedItem';
    }

    if (!inBasketComponent) {
      setNotificationText(text);
      setProductAdded(productAdded);
      openNotification();
    }
  };

  const alreadyInBasket = () => {
    setProductAdded(null);
    setNotificationText('alreadyInBasket');
    openNotification();
  };

  const renderItem = () => {
    const { name, images = {}, persons = [], totals = {} } = productAdded;

    return (
      <div className="article">
        <span className="horizontal-line"/>
        <div className="item">
          <div className="item-image">
            {images.Image && (
              <img src={images.Image} className="inline-block cover" alt={name} />
            )}
          </div>
          <p className="item-title" data-test-id="cart-itemTitle">{name}</p>
          <p className="author">
            {persons.map(item => item.name).join(', ')}
          </p>
          <p className="price-small" data-test-id="cartItemPrice">
            <span>{numFormatToCurrency(totals.total?.value)}</span>
          </p>
        </div>
        <span className="horizontal-line"/>
      </div>
    );
  };

  const opacity = notificationOpen ? '100' : '0';
  return (
    <div className="mini-basket en" style={{ opacity }}>
      <div className="notification-popup" data-test-id="basketPopup">
        {productAdded && (
          <div className="cart receipt popup">
            <p className="cart-title">
              <Text>minibasket.added</Text>
              <a href="#" onClick={closeNotification}>
                <Icon name="ico-error" size={100} />
              </a>
            </p>
            <span>{renderItem()}</span>
            <div className="row pricing">
              <div className="pricing-item">
                <div className="col-xs-8">
                  <p className="price-specification"><Text>minibasket.productcount</Text></p>
                </div>
                <div className="col-xs-4">
                  <p className="price-specification text-right" data-test-id="cartTotalAmount">{store.getBasketItemsCount()}</p>
                </div>
              </div>

              {discount !== 0 && (
                <div className="pricing-item">
                  <div className="col-xs-8">
                    <p className="price-specification"><Text>checkout.txt.discount</Text></p>
                  </div>
                  <div className="col-xs-4">
                    <p className="price-specification text-right">- {numFormatToCurrency(discount)}</p>
                  </div>
                </div>
              )}

              <div className="pricing-item">
                <div className="col-xs-8">
                  <p className="price-specification"><Text>checkout.txt.shippingcosts</Text></p>
                </div>
                <div className="col-xs-4">
                  <p className="price-specification text-right">
                    {store.getBasketTotalsShipping() ? numFormatToCurrency(store.getBasketTotalsShipping()) : t('checkout.txt.free')}
                  </p>
                </div>
              </div>

              {shippingRebate !== 0 && (
                <div className="pricing-item">
                  <div className="col-xs-8">
                    <p className="price-specification"><Text>minibasket.txt.shippingRebate</Text></p>
                  </div>
                  <div className="col-xs-4">
                    <p className="price-specification text-right">- {numFormatToCurrency(shippingRebate)}</p>
                  </div>
                </div>
              )}

              <div className="pricing-item">
                <div className="col-xs-8">
                  <p className="price-specification">
                    <b><Text>minibasket.total</Text></b>
                  </p>
                </div>
                <div className="col-xs-4">
                  <p className="price-specification text-right" data-test-id="cartTotalPrice">
                    <b>{store.getBasketTotal() ? numFormatToCurrency(store.getBasketTotal()) : t('checkout.txt.free')}</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="button-row">
              <Button
                data-test-id="button-continueMiniBasket"
                variant="secondary"
                size="small"
                onClick={closeNotification}
              >
                <Text>minibasket.continueshopping</Text>
              </Button>
              <Button
                data-test-id="buttonn-toCheckoutMiniBasket"
                variant="primary"
                size="small"
                onClick={handleNavigation}
              >
                <Text>minibasket.pay</Text>
              </Button>
            </div>
          </div>
        )}
        {notificationText && (
          <div className="cart receipt popup">
            <div className="row pricing">
              <div className="col-xs-12 already-in-basket-row">{notificationTexts[notificationText]}</div>
              <div className="col-xs-12 already-in-basket-row">
                <span className="horizontal-line"/>
              </div>
              <div className="col-xs-12 already-in-basket-row">
                <Button
                  data-test-id="button-allreadyInBasket"
                  variant="primary"
                  size="large"
                  onClick={handleNavigation}
                >
                  <Text>minibasket.tocart</Text>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MiniBasket;
