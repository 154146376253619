import React from 'react';
import Container from '../../ui_container/component';
import Typeset from '@components/base_typeset/component';
import Text from '@components/ui_text/component';
import './styles.scss';

import visa from './icons/visa.svg';
import mastercard from './icons/mastercard.svg';
import becommerce from './icons/becommerce.svg';
import ideal from './icons/ideal.svg';
import paypal from './icons/paypal.svg';
import thuiswinkel from './icons/thuiswinkel.svg';
import boekenbonImage from './icons/boekenbon.png';
import vvvImage from './icons/vvv.png';
import ecommerceEuropeImage from './icons/ecommerce-europe.png';
import boekcadeau from './icons/boekcadeau.png';
import leescadeau from './icons/leescadeau.png';
import billink from './icons/billink.png';
import award from './icons/bruna-webshop-awards-min.png';

import { FOOTER } from '@config';
const { showAward = false, icons = [] } = FOOTER;

const IconBar = () => {
  const iconMap = {
    becommerce,
    ideal,
    mastercard,
    paypal,
    thuiswinkel,
    visa,
    boekenbon: boekenbonImage,
    vvv: vvvImage,
    ecommerceEurope: ecommerceEuropeImage,
    boekcadeau,
    leescadeau,
    billink,
  };

  const getIconComponent = (key) => {
    const icon = iconMap[key];
    // For .png files
    const isString = typeof icon === 'string';
    if (isString) {
      return <img src={icon} height="42"/>;
    }
    const Component = icon;
    return <Component />;
  };

  if (!icons.length) {
    return null;
  }

  return (
    <div className="footer-icon-bar">
      <Container>
        <div className="footer-flex-container">
          {showAward && (
            <div className="award">
              <div className="award-img">
                <img src={award} alt="" />
              </div>
              <div className="award-text">
                <Typeset tag="div" bold tight><Text>footer.webshop-award</Text></Typeset>
                <Typeset tag="div" scale="0" tight><Text>footer.webshop-award.category</Text></Typeset>
              </div>
            </div>
          )}
          <div className="footer-icon-list">
            {icons.map((icon, index) => {
              if (!iconMap[icon.key]) {
                return;
              }
              return (
                <a href={icon.url} target={icon.target || ''} key={index}>
                  {getIconComponent(icon.key)}
                </a>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default IconBar;
