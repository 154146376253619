export const GOOGLE_MAPS_API_KEY = 'AIzaSyBlrJfulCjPRNQV6P_EVVbFoG0LXFcx3No';

export const PRIMARY_CATEGORIES = [
  'boeken',
  'engelse-boeken',
  'anderstalige-boeken',
  'ebooks',
  'muziek',
  'spel-speelgoed',
  'games',
  'film',
  'kantoor',
  'hobby',
];

// Component prefixes
export const CONTENT_PREFIX = 'content_';
export const CAMPAIGN_PREFIX = 'campaign_';
export const BLOG_PREFIX = 'blog_';

// Component ID's
export const CMP_HEADER = 'header';
export const CMP_FOOTER = 'footer';
export const CMP_USP_LINKS = 'usp_links';
export const CMP_SALE_BAR = 'sale_bar';
export const CMP_NOTIFICATION_BAR = 'notification_bar';
export const CMP_HOMEPAGE = 'homepage';
export const CMP_BALANCE_CHECKER = 'saldochecker';
export const CMP_B2B_LOGIN_PRODUCTS = 'b2b_login_products';
export const CMP_CHECKOUT_NOTIFICATION = 'checkout_notification_container';

// Checkout
export const SHIPPING_METHOD_DEFAULT = 'NormalDelivery';
export const SHIPPING_METHOD_STORE_PICKUP = 'StorePickup';
export const SHIPPING_METHOD_NORMAL_DELIVERY = 'NormalDelivery';

// Category Search filter
export const FILTER_BESTSELLERS = 'bestverkocht';
export const FILTER_RECENT = 'net-verschenen';
export const FILTER_PREORDER = 'alvast-reserveren';
export const FILTER_ALL = 'alle';

// Page ID's
export const SEARCHPAGE_ID = 'SearchResults';

// Misc
export const ICECAT_USERNAME = 'Audaxdigitaal';
export const BLOGOVERVIEW_PAGINATION_LIMIT = 12;
export const VERSION_HEADER = 'storefront-version';
export const BUILD_VERSION = `${VERSION}-${BUILD_NUMBER}`;

// B2B returns tabs
export const RETURNS_OVERVIEW = 'overzicht';
export const RETURNS_RETURN_REQUEST = 'retouraanvraag';
export const RETURNS_PICKUP_REQUEST = 'ophaalverzoek';

// B2B Manco tabs
export const MANCO_OVERVIEW = 'overzicht';
export const MANCO_SUBMIT = 'melden';
export const MANCO_WITHDRAW = 'intrekken';

// B2B return types
export const RETURN_TYPE_DEFAULT = 'R';
export const RETURN_TYPE_DAMAGE = 'Z';
export const RETURN_TYPE_MISPRINT = 'S';

// Storage keys
export const TOKEN_STORAGE = 'authentication-token';
export const USER_STORAGE = 'user';
export const BASKET_COUNT = 'basket-count';
export const APP_VERSION = 'app_version';
export const APP_RESET = 'app_reset';
export const GUEST_USER_STORAGE = 'guest_user';

// B2B Miscellaneous tabs
export const MISC_PRICE_STICKERS = 'prijsstickers' ;
export const MISC_CANCEL_ORDER = 'annuleren-bestelling';

// B2B Bulk order
export const BULK_ORDER_SUBMIT = 'bulkbestelling-invoeren';
export const MAX_QUANTITY_BULK_ORDER = 100;

// Supplier names
export const SUPPLIER_BETAPRESS = 'Betapress';

// Regular expressions
export const FORMAT_PASSWORD = '^[a-zA-Z0-9!"#$%&\'\(\\)*+\\x2c-.\\/:;<=>?@\\[\\\\\\]^_`{|}~]*$';
